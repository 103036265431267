/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../images/logo.webp";

function Header(props) {
  return (
    <>
      <nav className="navbar navbar-light navigation_bar navbar-expand-lg d-flex fixed-top">
        <a className="navbar-brand active_header" id="home_header" href="/#">
          <img
            src={logo}
            width="38"
            height="30"
            className="d-inline-block align-top mx-5"
            alt=""
          />
          Picxq
        </a>
        <button
          className="navbar-toggler mx-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav">
            <li className="nav-item">
              <a
                className="nav-link out_services_header"
                aria-current="page"
                href="/#our_services"
                id="our_services_header"
              >
                Our Services
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link about_header"
                id="about_header"
                href="/#about"
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link contact_header"
                id="contact_header"
                href="/#contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;
