/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import "./ourServices.css";
import { Gallery } from "react-grid-gallery";
import Header from "../../components/Header";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { Button } from "react-bootstrap";

let imageCustomComponent = (props) => {
  return (
    <div className="img_preview">
      {props.item.preview ? (
        <ImgComparisonSlider hover="hover" value={50} className="preview">
          <img slot="first" src={props.item.src} />
          <img slot="second" src={props.item.original} />
        </ImgComparisonSlider>
      ) : (
        <div className="preview">
          <img src={props.item.src} />
        </div>
      )}
    </div>
  );
};

function OurServices() {
  let images = [
    {
      src: require("../../images/gallery/2.webp"),
      width: 1200,
      height: 750,
      preview: false,
    },
    {
      src: require("../../images/gallery/decor_1.webp"),
      original: require("../../images/gallery/decor_2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/amb1.webp"),
      original: require("../../images/gallery/amb2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/bed.webp"),
      width: 1200,
      height: 750,
      preview: false,
    },
    {
      src: require("../../images/gallery/live1.webp"),
      original: require("../../images/gallery/live2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/dine1.webp"),
      original: require("../../images/gallery/dine2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/food1.webp"),
      original: require("../../images/gallery/food2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/arial1.webp"),
      original: require("../../images/gallery/arial2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/house1.webp"),
      original: require("../../images/gallery/house2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/slide1.webp"),
      width: 1200,
      height: 750,
      preview: false,
    },
    {
      src: require("../../images/gallery/slide4.webp"),
      width: 1200,
      height: 750,
      preview: false,
    },
    {
      src: require("../../images/gallery/slide5.webp"),
      width: 1200,
      height: 750,
      preview: false,
    },
    {
      src: require("../../images/gallery/slide6.webp"),
      width: 1200,
      height: 750,
      preview: false,
    },
    {
      src: require("../../images/gallery/home1.webp"),
      original: require("../../images/gallery/home2.webp"),
      width: 1200,
      height: 750,
      preview: true,
    },
    {
      src: require("../../images/gallery/virtual1.webp"),
      original: require("../../images/gallery/virtual2.jpg"),
      width: 1200,
      height: 750,
      preview: true,
    },
  ];

  const [imagesSet] = useState(images.sort(() => Math.random() - 0.5));
  const [view, setView] = useState(false);
  const [imageSource, setImageSource] = useState({});
  const [indexCount, setIndexCount] = useState(0);

  let clicked = (props) => {
    setIndexCount(props);
    setView(true);
    setImageSource(imagesSet[props]);
  };

  let increment = () => {
    let tempIndex = indexCount;
    if (images.length <= tempIndex + 1) {
      return;
    }
    setIndexCount(tempIndex + 1);
    setImageSource(imagesSet[tempIndex]);
  };

  let decrement = () => {
    let tempIndex = indexCount;
    if (tempIndex === 0) {
      return;
    }
    setIndexCount(tempIndex - 1);
    setImageSource(imagesSet[tempIndex]);
  };

  return (
    <div className="services">
      <Header />
      <div>
        <Gallery
          rowHeight={300}
          images={imagesSet}
          thumbnailImageComponent={imageCustomComponent}
          onClick={clicked}
          enableImageSelection={false}
        />
      </div>
      {view ? (
        <div className="full_window">
          <Button
            className="btn-transparent close"
            onClick={() => setView(false)}
          >
            X
          </Button>
          {imageCustomComponent({ item: imageSource })}
          <div className="skip">
            <Button
              className="btn-transparent larrow"
              onClick={() => decrement()}
            >
              {"<"}
            </Button>
            <Button
              className="btn-transparent rarrow"
              onClick={() => increment()}
            >
              {">"}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default OurServices;
