/* eslint-disable no-sparse-arrays */
import React, { useState } from "react";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const images = {
  hdr: {
    item: {
      src: require("../images/gallery/arial1.webp"),
      original: require("../images/gallery/arial2.webp"),
      preview: true,
      text: [
        "High Dynamic Range (HDR) blending is a sophisticated photo editing technique that combines multiple images with varying exposures to create a final image with exceptional detail and clarity.",
        ,
        "Capture Multiple Exposures, Align the Images, Blend Exposures, Adjust Color and Tone, Remove Artifacts, Finalize and Export.",
      ],
    },
  },
  deculter: {
    item: {
      src: require("../images/gallery/decor_1.webp"),
      original: require("../images/gallery/decor_2.webp"),
      preview: true,
      text: [
        "Our team of experienced and professional digital de-cluttering editors is skilled in removing unwanted elements from your images, including general clutter, rubbish, pool debris, vehicles, signs, posters, and even altering water colors or wall pictures.",
      ],
    },
  },
  dayToDusk: {
    item: {
      src: require("../images/gallery/homes1.webp"),
      original: require("../images/gallery/homes2.webp"),
      preview: true,
      text: [
        "Transform your daytime property photos into captivating twilight or nighttime images with our versatile image editing services.",
        "Our expertise in various styles and price points allows us to create stunning twilight and nighttime effects that grab the attention of potential buyers.",
      ],
    },
  },
  skyReplacement: {
    item: {
      src: require("../images/gallery/slide4.webp"),
      original: require("../images/gallery/slide5.webp"),
      preview: true,
      text: [
        "Enhance the appeal of your property photos with Tec Pixel’s specialized sky and grass replacement services. If your images suffer from dull or washed-out skies and lifeless grass, we can transform them into vibrant, eye-catching visuals with clear, bright skies and lush, green grass.",
        "By outsourcing your sky and grass edits to Tec Pixel, you gain access to top-tier professional expertise at a cost-effective price.",
      ],
    },
  },
};

function Description() {
  const navigate = useNavigate();
  let [view, setView] = useState(false);
  let [imageSource, setImageSource] = useState({});
  let [viewText, setViewText] = useState(false);

  let imageCustomComponent = (props) => {
    return (
      <div className="img_preview">
        {props.item.preview ? (
          <>
            <ImgComparisonSlider hover="hover" value={50} className="preview">
              <img
                slot="first"
                src={props.item.src}
                loading="lazy"
                alt="loading.."
              />
              <img
                slot="second"
                src={props.item.original}
                loading="lazy"
                alt="loading.."
              />
            </ImgComparisonSlider>

            {viewText ? (
              <div className="text">
                {props.item.text.map((content, key) => (
                  <p key={key}>{content}</p>
                ))}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className="preview">
            <img src={props.item.src} loading="lazy" alt="loading.." />
          </div>
        )}
      </div>
    );
  };

  let sourceUpdate = (value) => {
    setImageSource(images[value]);
    setView(true);
    setViewText(true);
  };
  return (
    <div className="description section" id="our_services">
      <div className="card " onClick={() => sourceUpdate("hdr")}>
        {imageCustomComponent(images.hdr)}
        <div className="text">
          <h1>HDR Blending</h1>
          <p>
            Capture the light embrace the moment with multiple exposures
            Illuminate Your Memories, One Frame at a Time.
          </p>
        </div>
      </div>
      <div className="card " onClick={() => sourceUpdate("deculter")}>
        <div className="text">
          <h1>Declutter</h1>
          <p>
            Clear the clutter, capture the beauty Embrace simplicity, unveil
            elegance.
          </p>
        </div>
        {imageCustomComponent(images.deculter)}
      </div>
      <div className="card " onClick={() => sourceUpdate("dayToDusk")}>
        {imageCustomComponent(images.dayToDusk)}
        <div className="text">
          <h1>Day to Dusk</h1>
          <p>
            From dawn's light to dusk's embrace Illuminate Your Journey, From
            Dawn to Dusk
          </p>
        </div>
      </div>
      <div className="card " onClick={() => sourceUpdate("skyReplacement")}>
        <div className="text">
          <h1>Sky Replacement</h1>
          <p>
            Transform dull to dazzling Ignite Your Spark, Illuminate Your World
          </p>
        </div>
        {imageCustomComponent(images.skyReplacement)}
      </div>
      <div className="float" id="float_button">
        <Button
          onClick={() => {
            navigate("/services", { replace: true });
          }}
        >
          Want Know More...
        </Button>
      </div>
      {view ? (
        <div className="full_window">
          <Button
            className="btn-transparent close"
            onClick={() => {
              setView(false);
              setViewText(false);
            }}
          >
            X
          </Button>
          {imageCustomComponent(imageSource)}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default Description;
