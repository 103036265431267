/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./About.css";
import svgArt from "../../images/undraw_product_photography_91i2(1).svg";

function About() {
  return (
    <div className="about section" id="about">
      <div className="svg">
        <img src={svgArt} />
      </div>
      <div className="text_about">
        <div><h3>About Us</h3></div>
        <p>
          Transform your real estate listings with Picxq’s premium photo editing
          services!.
        </p>
        <p>
          Our expert team enhances commercial and residential property images to
          make them stand out, attract more buyers, and boost property values.
        </p>
        <p>
          Elevate your real estate marketing with our cutting-edge editing
          solutions.
        </p>
        <p>
          Picxq offers wide range of services to real estate agents, developers,
          and online retailers, providing a comprehensive range of professional
          photo editing services.
        </p>
        <p>
          we operate around the clock 24/7 to ensure timely delivery and optimal
          service for all your photo editing needs. Our team utilizes the latest
          image processing techniques to produce stunning, high-quality results
          while staying within your budget.
        </p>
      </div>
    </div>
  );
}

export default About;
