import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/HomePage/Home";
import OurServices from "./pages/Services/OurServices";
import { useEffect } from "react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/services",
    element: <OurServices />,
  },
]);

function App() {
  const handleScroll = () => {
    let sectionMap = {
      home: "home_header",
      our_services: "our_services_header",
      about: "about_header",
      contact: "contact_header",
    };
    const scrollPosition = window.scrollY;

    let handleClassToggle = (tag) => {
      if (
        document
          .getElementById(tag)
          .className.split(" ")
          .includes("header_active")
      ) {
        return true;
      } else {
        Object.values(sectionMap).map(
          (tag) =>
            (document.getElementById(tag).className = document
              .getElementById(tag)
              .className.replace(" header_active", ""))
        );
        document.getElementById(tag).className = document.getElementById(
          tag
        ).className += " header_active";
      }
    };

    // Loop through all sections to find the active one
    document.querySelectorAll(".section").forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      let idTad = section.getAttribute("id") || "home";
      if (
        scrollPosition >= sectionTop - 50 &&
        scrollPosition < sectionTop + sectionHeight - 50
      ) {
        handleClassToggle(sectionMap[idTad]);
      }
      let floatButton = document.getElementById("float_button");
      if (scrollPosition > 350 && scrollPosition < 1750) {
        floatButton.style.position = "fixed";
        floatButton.style.top = "90vh";
      } else {
        floatButton.style.position = "relative";
        floatButton.style.top = 0;
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
