import React from "react";
import { Carousel } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import slide_1 from "../images/slides/1.webp";
import slide_2 from "../images/slides/2.webp";
import slide_3 from "../images/slides/3.webp";
import slide_4 from "../images/slides/4.webp";
import slide_5 from "../images/slides/5.webp";

function TextBlock() {
  let textQuotes = [
    "Quality.",
    "New Innovations.",
    "Creativity.",
    "Timely Delivery.",
    "Client Satisfaction.",
    "Client Relationship.",
  ];
  let staticQuotes = "We concentrated on ";
  let textSequence = textQuotes.flatMap((textQuote) => [
    staticQuotes + textQuote,
    2000,
  ]);
  return (
    <div className="text section" id="home">
      <div>
        <Carousel controls={false} indicators={false}>
          <Carousel.Item>
            <img
              className="d-block slide_image"
              src={slide_1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block slide_image"
              src={slide_2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block slide_image"
              src={slide_3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block slide_image"
              src={slide_4}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block slide_image"
              src={slide_5}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <div id="textblock">
        <div id="textblock-container">
          <h1 id="textblock-title">What is picxq?</h1>
          <p id="textblock-content">
            Welcome to Picxq. We are Creative Agency.
          </p>
          <TypeAnimation
            sequence={textSequence}
            speed={5}
            style={{ fontSize: "1.2em", fontWeight: "bold", color: "white" }}
            repeat={Infinity}
          />
        </div>
      </div>
    </div>
  );
}

export default TextBlock;
