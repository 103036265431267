import React from "react";
import "./Contact.css";
import logo from "../../images/logo.webp";

function Contact() {
  return (
    <div className="contact section" id="contact">
      <img src={logo} alt="loading" loading="lazy" />
    </div>
  );
}

export default Contact;
