import "./home.css";
import Header from "../../components/Header";
import TextBlock from "../../components/TextBlock";
import Description from "../../components/Description";
import About from "../AboutPage/About";
import Contact from "../ContactPage/Contact";

function App() {
  return (
    <div className="App">
      <Header />
      <TextBlock />
      <Description />
      <About />
      <Contact />
    </div>
  );
}

export default App;
